import { Container, Row, Col } from "react-bootstrap";
import logoRaio from '../assets/img/logo-raio.svg'
import imgPassoPasso from '../assets/img/img-passo.png'
import imgEquipe from '../assets/img/i-equipe.png'
import imgAvaliacao from '../assets/img/i-avaliacao.png'
import imgProposta from '../assets/img/i-proposta.png'
import imgEconomia from '../assets/img/i-alcancada.png'
import { ChevronRight  } from 'react-bootstrap-icons';



export const EficienciaEnergetica = () => {
  return (
    <section className="eficienciaEnergetica" id="eficiencia">
      <Container>
        <Row className="aligh-items-center text-center" style={{ justifyContent: "center", marginBottom: 72 }}>
          <Col xs={12} md={6} xl={7}>
            <p style={{fontSize: 40}}>CONHEÇA NOSSA <b>SOLUÇÃO DE EFICIÊNCIA ENERGÉTICA.</b></p>
            <img src={logoRaio} alt="logoRaio" style={{ width: 32, height: 32, marginTop: 10, marginBottom: 10 }}></img>
            <p style={{fontFamily: "Red Hat Display", fontSize: "18px"}}>Com a digitalização 
                e uso da inteligência artificial <b>(IA)</b>, 
                nossa equipe de engenheiros especializados analisa suas faturas
                 diariamente, buscando reduzir seus custos e melhorar suas finanças, 
                 enquanto você foca no crescimento do seu negócio.</p>
          </Col>
        </Row>
        <Row className="aligh-items-center text-center hidden-passo-mobile" style={{ justifyContent: "center", marginBottom: 72 }}>
          <Col>
                <img src={imgPassoPasso} alt="logoRaio" style={{ width: 1000, height: 434, marginTop: 10, marginBottom: 10 }}></img>

          </Col>
        </Row>
        <Row className="aligh-items-center show-passo-mobile" style={{ display: "none",justifyContent: "center", marginBottom: 72 }}>
            <Col>
                <Row>
                    <Col md={{ span: 6, offset: 3 }} style={{display: "flex",justifyContent: "flex-start"}}>
                        <img src={imgEquipe} alt="logoEquipe" style={{ width: 88, height: 88, marginTop: 10, marginBottom: 10 }}></img>
                        <p style={{paddingTop: 16, marginLeft: 30}}>
                            <b>DIAGNÓSTICO DA EQUIPE.</b><br/> 
                            Realizado por IA - onde serão extraídos e analisados os dados gerados, 
                            levando indicadores.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 6, offset: 3 }} style={{display: "flex",justifyContent: "flex-start"}}>
                        <img src={imgAvaliacao} alt="logoAvalia" style={{ width: 88, height: 88, marginTop: 10, marginBottom: 10 }}></img>
                        <p style={{paddingTop: 16, marginLeft: 30}}>
                            <b>DIAGNÓSTICO DA EQUIPE.</b>
                            Realizado por IA - onde serão extraídos e analisados os dados gerados, levando indicadores.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 6, offset: 3 }} style={{display: "flex",justifyContent: "flex-start"}}>  
                        <img src={imgProposta} alt="logoRaio" style={{ width: 88, height: 88, marginTop: 10, marginBottom: 10 }}></img>
                        <p style={{paddingTop: 16, marginLeft: 30}}>
                            <b>DIAGNÓSTICO DA EQUIPE.</b>
                            Realizado por IA - onde serão extraídos e analisados os dados gerados, levando indicadores.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 6, offset: 3 }} style={{display: "flex",justifyContent: "flex-start"}}>
                        <img src={imgEconomia} alt="logoRaio" style={{ width: 88, height: 88, marginTop: 10, marginBottom: 10 }}></img>
                        <p style={{paddingTop: 16, marginLeft: 30}}>
                            <b>DIAGNÓSTICO DA EQUIPE.</b>
                            Realizado por IA - onde serão extraídos e analisados os dados gerados, levando indicadores.
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="aligh-items-center text-center" style={{ justifyContent: "center" , marginBottom: 72}}>
            <Col>

                <a href="https://wa.me/5585988651000?text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20Consultoria%20Gratuita!">
                      <button className="banner-enviar adjust-buttom-font-size" >QUERO ECONOMIZAR
                          <ChevronRight
                              size={18}
                              color="#172F4D"
                          />
                      </button>
                      </a>
            </Col>
        </Row>
      
      </Container>
    </section>
  );
}