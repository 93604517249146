import { useState, useEffect } from "react";
import { Container, Row, Col, Link } from "react-bootstrap";
import headerImg from "../assets/img/Logo.png";
import headerTubo from "../assets/img/Imagem-Homem.png";
import { ChevronRight  } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  // const toRotate = [ "Web Developer", "Web Designer", "UI/UX Designer" ];
  const toRotate = [ "Economia" ];
  // const period = 1000;
  const period = 500;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(200);
      // setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                {/* <img src={headerImg} className="tagline" alt="Header Img"/> */}
                  <p style={{fontSize: "48px", lineHeight: "57.6px"}}> 
                    <span className="txt-rotate" dataperiod="500" data-rotate='[ "Economia" ]'><br/>
                    <span style={{fontWeight: 800}} className="wrap">{text}</span></span> 
                    <span> na</span> <br/>
                    <span> conta de <b style={{fontWeight: 800}}>Energia</b></span> <br/>
                    <span> da sua <b style={{fontWeight: 800}}>Empresa.</b></span> 
                  </p>
                  <p style={{fontFamily: "Red Hat Display", fontSize: "18px", lineHeight: "27px"}}>
                    Deixe a Análise Técnica para Nós, 
                    <b
                    style={{color: "#FF9D0A"}}> Sem Custos.</b> <br/>
                    Sua Empresa Prospera. Sua Conta de Energia Diminui. 
                  </p>
                  <a href="https://wa.me/5585988651000?text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20Consultoria%20Gratuita!">
                  <button className="banner-enviar adjust-buttom-font-size" >FALE CONOSCO
                  <ChevronRight 
                    size={18}
                    color="#172F4D"
                  />
                  </button>
                  </a>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              <img src={headerTubo} className="adjust-img-homem-mobile" style={{maxWidth: "510px", height: "auto"}} alt="Header Img"/> 
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}