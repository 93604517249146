import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { ContasDeEnergias } from "./components/ContasDeEnergias";
import { Skills } from "./components/Skills";
import { EficienciaEnergetica } from "./components/EficienciaEnergetica";
import { BeneficiosPrincipais } from "./components/BeneficiosPrincipais";
import { Formulario } from './components/Formulario';
import { Rodape } from './components/Rodape';
import { PreCadastro } from './components/PreCadastro';
import { Resultados } from './components/Resultados';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <ContasDeEnergias/>
      <Skills />
      <Resultados/>
      <EficienciaEnergetica/>
      <BeneficiosPrincipais />
      <PreCadastro />
      <Rodape />
    </div>
  );
}

export default App;
